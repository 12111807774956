import React, { createContext, useContext, useState } from 'react'
import { useContentfulSettings } from '~/hooks/use-contentful-settings'
import { handleScore } from '~/utils/handleScore'
import { generateId, runTracking } from '~/utils/misc'
import {
  convertUserAnswersToQueryParams,
  convertUserDataFromQueryParamsToObject,
  type UserAnswers,
} from '~/utils/queryParameters'
import { PAGES_SLUGS } from '~/utils/settings'
import { UIWithChildren } from '~/utils/types'

interface QuizContextType {
  questions?: Queries.QuestionDataFragment[]
  currentQuestionData?: Queries.QuestionDataFragment
  userSelectedAnswers: UserAnswers
  handleAnswerSelect: (questionIndex: number, answerIndex: number) => void
  hasQuizStarted: boolean
  setHasQuizStarted: (value: boolean) => void
  currentQuestion: number
  setCurrentQuestion: (value: number) => void
  isTransitioning: boolean
  setIsTransitioning: (value: boolean) => void
  handleQuizProgress: () => void
}

const QuizContext = createContext<QuizContextType | undefined>(undefined)

export const QuizProvider = ({ children }: UIWithChildren) => {
  const { profiles, questions } = useContentfulSettings()
  const [currentQuestion, setCurrentQuestion] = useState(0)
  const [hasQuizStarted, setHasQuizStarted] = useState(false)
  const [userSelectedAnswers, setuserSelectedAnswers] = useState<UserAnswers>(
    [],
  )
  const [isTransitioning, setIsTransitioning] = useState(false)

  const handleAnswerSelect = (questionIndex: number, answerIndex: number) => {
    const updatedAnswers = userSelectedAnswers.map((answer) => {
      if (answer?.questionIndex === questionIndex) {
        return {
          ...answer,
          userAnswerIndex: answerIndex,
        }
      }
      return answer
    })

    if (
      updatedAnswers.some((answer) => answer?.questionIndex === questionIndex)
    ) {
      setuserSelectedAnswers(updatedAnswers)
    } else {
      setuserSelectedAnswers([
        ...updatedAnswers,
        {
          questionIndex,
          userAnswerIndex: answerIndex,
        },
      ])
    }
  }

  const handleQuizProgress = () => {
    if (!hasQuizStarted) {
      runTracking('start')
      setHasQuizStarted(true)
    } else {
      runTracking(`question_${currentQuestion + 1}`)
      const isLastQuestion = questions
        ? currentQuestion === questions.length - 1
        : null
      const queryParameters =
        convertUserAnswersToQueryParams(userSelectedAnswers)

      const cid = convertUserDataFromQueryParamsToObject()

      if (isLastQuestion) {
        const { user: global } = handleScore({
          questions,
          profiles,
          userAnswers: userSelectedAnswers,
        })
        window.location.href = `/${PAGES_SLUGS.results}?${queryParameters}&scorepercentage=${global.userProfilePercentage}${cid && `&localId=${generateId()}`}`
      } else {
        setIsTransitioning(true)
        setCurrentQuestion(
          (prevCurrentQuestion: number) => prevCurrentQuestion + 1,
        )
        setIsTransitioning(false)
      }
    }
  }

  const currentQuestionData = questions && questions[currentQuestion]

  return (
    <QuizContext.Provider
      value={{
        questions,
        hasQuizStarted,
        setHasQuizStarted,
        isTransitioning,
        setIsTransitioning,
        currentQuestion,
        setCurrentQuestion,
        currentQuestionData,
        userSelectedAnswers,
        handleAnswerSelect,
        handleQuizProgress,
      }}
    >
      {children}
    </QuizContext.Provider>
  )
}

export const useQuiz = (): QuizContextType => {
  const context = useContext(QuizContext)
  if (context === undefined) {
    throw new Error('useQuiz must be used within a QuizProvider')
  }
  return context
}
