import { FormFieldsNames, GatsbyActiveEnvs } from '~/utils/types'

// Check these settings before building the app
const IS_TEST_MODE = false
// Force the value of GATSBY_ACTIVE_ENV in this file
const FORCED_ENV: GatsbyActiveEnvs = 'prod'

const createSettingsWithCustomTypes = <
  T extends { formFieldsToDisplayWithSorting: FormFieldsNames[] },
>(
  settings: T,
): T => {
  return {
    ...settings,
  }
}

const SETTINGS_BY_ENV = {
  preprod: createSettingsWithCustomTypes({
    showQuestionCustomImage: true,
    isEvaluationPageGenerated: true,
    siteUrl: 'https://preprod.scc-migration-windows-11.com',
    apiFile: 'saveinfopreprod.php',
    formFieldsToDisplayWithSorting: [
      'company',
      'lastName',
      'firstName',
      'position',
      'email',
      'phone',
    ],
    formFieldsDefaultValue: {
      company: '',
      lastName: '',
      firstName: '',
      position: '',
      email: '',
      phone: '',
    },
  }),
  prod: createSettingsWithCustomTypes({
    showQuestionCustomImage: false,
    isEvaluationPageGenerated: false,
    siteUrl: 'https://www.scc-migration-windows-11.com',
    apiFile: 'saveinfoprod.php',
    formFieldsToDisplayWithSorting: [
      'lastName',
      'firstName',
      'email',
      'position',
      'company',
    ],
    formFieldsDefaultValue: {
      lastName: '',
      firstName: '',
      email: '',
      position: '',
      company: '',
    },
  }),
}

export const SETTINGS = {
  prefillFormFields: IS_TEST_MODE,
  disableFormSubmission: IS_TEST_MODE,
  isAnswerMandatoryToGoToNextQuestion: !IS_TEST_MODE,
  answersMaxCount: 4,
  questionsMaxCount: 8,
  ...SETTINGS_BY_ENV[FORCED_ENV ?? process.env.GATSBY_ACTIVE_ENV],
}

export const PAGES_SLUGS = {
  '/': '/',
  evaluation: 'evaluation',
  results: 'results',
}

export type pagesSlugs = keyof typeof PAGES_SLUGS
