export function removeNullEntries<T>(
  array: readonly (T | null)[] | undefined | null,
): T[] {
  return (array || []).filter((item): item is T => item !== null)
}

export const generateId = () => Math.random().toString(16).slice(2)

export const isBrowser = typeof window !== 'undefined'

export const runTracking = (action: string) => {
  if (isBrowser) {
    ;(window as any).dataLayer = (window as any).dataLayer || []
    ;(window as any).dataLayer.push({
      event: 'navigation',
      action,
    })
  }
}
