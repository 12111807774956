import { graphql, useStaticQuery } from 'gatsby'

export const useContentfulSettings = () => {
  const data = useStaticQuery(graphql`
    query {
      contentfulMainSiteGlobalSettings(title: { eq: "SCC" }) {
        questions {
          ...QuestionData
        }
        profiles {
          ...ProfileData
        }
        documentToDownload {
          file {
            url
          }
        }
      }
    }
  `)

  return data.contentfulMainSiteGlobalSettings
}
